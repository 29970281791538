<template>
  <div class="item">
    <router-link :to="`/news/${state.id}`" class="poster">
      <img :src="state.coverImage" />
    </router-link>
    <div class="info">
      <div class="title">
        <router-link :to="{
          path:`/news/${state.id}`,
          query:{
            group:props.group
          }
        }">{{ state.title }}</router-link>
        <router-link class="em">{{ state.hostUnit }}</router-link>
      </div>
      <div class="user">
        <div class="avator">
          <svg-icon icon="icon_avator" />
          {{ state.userNickname }}
        </div>
        <el-rate v-model="rate" disabled></el-rate>
      </div>
      <div class="desc"></div>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, reactive, computed } from 'vue'
import { useRoute } from 'vue-router'
const props = defineProps({
  // 新闻分组（1，大学生创业，2：退伍军人创业，3：大健康
  group:{
    type:String,
    required:true
  },
  data: {
    type: Object,
    required: false,
  },
})
const route = useRoute()
const rate = ref(0)
const prjUrl = computed(() => {
  return '/fund/' + state.id
})
const isNewWindow = computed(() => {
  return route.path.indexOf('ucenter') != -1 ? '_blank' : ''
})
const state = reactive({ ...(props.data || {}) })
rate.value = Number(state.userStarLevel) || 0
</script>
<style lang="scss" scoped>
.item {
  display: flex;
  .poster {
    width: 200px;
    height: 115px;
    display: block;
    flex-basis: 200px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .info {
    padding-left: 20px;
    flex: 1;
    .title {
      font-size: 16px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      .em {
        color: #5cb0d4;
        font-size: 12px;
        cursor: default;
      }
    }
    .user{
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
    .avator {
      display: flex;
      align-items: center;
      margin-right: 20px;
      .svg-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .desc {
      font-size: 14px;
      margin-top: 10px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4; /* 显示的行数 */
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.5em; /* 每行的高度 */
      max-height: 6em; /* line-height * 行数 */
    }
  }
}
</style>
